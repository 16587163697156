import { simpleApi } from '~/common/API/simpleApi';
import { ElNotification } from 'element-plus';

interface IGetChannelsParams {
  entityType?: string;
  status?: string[];
  purpose?: string[];
  userId?: number;
  createdAtFrom?: string;
  createdAtTo?: string;
  page?: number;
  pageSize?: number;
}

export default class ModerationService {

  static getChannels = async (data: { params?: IGetChannelsParams, query?: string } = {}) => {
    const query = data.query || '';
    const endpoint = `/moderation/search`;
    return new Promise((resolve) => {
      simpleApi(endpoint, { params: data.params, query: data.query })
      .then((res) => {
        resolve(res);
      }).catch((err) => {
        console.error(err);
        resolve([]);
          // ElNotification({
          //   title: 'Ошибка получения данных',
          //   message: 'попробуйте чуть позже или свяжитесь с администратором...',
          //   type: 'error',
          // });
      });
    });
  };

  static confirmModerate = async (payload: number) => {
    const endpoint = '/moderation/approve';
    return new Promise((resolve) => {
      simpleApi(endpoint, { method: 'PUT', body: { id: payload } })
      .then((res) => {
        ElNotification({
            title: 'Успешная модерация',
            message: 'канал успешно прошел модерацию',
          type: 'success',
        });
        resolve(res);
      }).catch((err) => {
        console.error(err);
        resolve(false);
        ElNotification({
          title: 'Ошибка модерирования канала',
          message: 'попробуйте чуть позже или свяжитесь с администратором...',
          type: 'error',
        });
      });
    });
  }

  static rejectModerate = async (payload: {channelId: number, reason: string}) => {
    const endpoint = '/moderation/reject';
    return new Promise((resolve) => {
      simpleApi(endpoint, { method: 'PUT', body: { id: payload.channelId, reason: payload.reason } })
      .then((res) => {
        ElNotification({
            title: 'Успешное отклонение',
            message: 'Модерация отклонена',
          type: 'success',
        });
        resolve(res);
      }).catch((err) => {
        console.error(err);
        resolve(false);
        ElNotification({
          title: 'Ошибка модерирования канала',
          message: 'попробуйте чуть позже или свяжитесь с администратором...',
          type: 'error',
        });
      });
    });
  }

  // Метод для получения количества запросов на модерацию
  static getModerationCount = async (data: { query?: string } = {}) => {
    const query = data.query || ''; // Получаем строку запроса или устанавливаем пустую строку, если она не задана
    const endpoint = `/moderation/count`; // Устанавливаем конечную точку API
    return new Promise((resolve) => {
      simpleApi(endpoint, { query: data.query }) // Выполняем запрос к API с использованием simpleApi
      .then((res) => {
        resolve(res); // Возвращаем ответ
      }).catch((err) => {
        console.error(err); // Логируем ошибку
        resolve(false); // Возвращаем false в случае ошибки
      });
    });
  }
}
