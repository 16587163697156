import { defineStore } from 'pinia';
import ModerationService from '~/services/ModerationService';
import { simpleApi } from '~/common/API/simpleApi';

export const useModerationStore = defineStore('moderation', () => {

  const moderateCount = ref<number>(0);

  const confirmModerate = async (payload: number) => {
    return new Promise((resolve) => {
      ModerationService.confirmModerate(payload).then((res) => {
        resolve(res);
      }).catch((err) => {
        resolve(false);
      });
    });
  }

  const rejectModerate = async (payload: {channelId: number, reason: string}) => {
    return new Promise((resolve) => {
      ModerationService.rejectModerate(payload).then((res) => {
        resolve(res);
      }).catch((err) => {
        resolve(false);
      });
    });
  }

  const newValuesFromAdmin = async (data: any) => {
    if (data.compare.length === 0) return;
    const saveData = {
      id: data.newValues.id,
    }
    data.compare.forEach((item: any) => {
      const type = item.type === 'categories' ? 'categoriesIds' : item.type;
      saveData[type] = data.newValues[type];
    });
    return new Promise((resolve) => {
      simpleApi("/place/telegram/update", { method: 'PUT', body: saveData }) // Выполняем запрос к API с использованием simpleApi
      .then((res) => {
        console.log(res);
        resolve(res); // Возвращаем ответ
      }).catch((err) => {
        console.error(err); // Логируем ошибку
        resolve(false); // Возвращаем false в случае ошибки
      });
    });
  }

  const getAlerts = async () => {
    try {
      console.log(1);
      const res = await ModerationService.getModerationCount({query: '?status[]=pending'});
      console.log(2, res);
      if (res && res.moderationRequestsCount) {
        console.log(3);
        moderateCount.value = res.moderationRequestsCount.find((item: any) => item.status === 'pending')?.count || 0;
        console.log('moderateCount', moderateCount.value);
      }
    } catch (err) {
      console.error(err); // Обработка ошибки, если необходимо
    }
  }


  getAlerts();


  return {
    moderateCount,
    confirmModerate,
    rejectModerate,
    newValuesFromAdmin,
    getAlerts
  };
});
